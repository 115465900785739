import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
     
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Conéctate con nosotros en las redes sociales:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='facebook-f' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='twitter' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='google' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='instagram' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='linkedin' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='github' />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon color='secondary' icon='gem' className='me-3' />
                DETALLES PERU
              </h6>
              <p>
              Somos una empresa con años de experiencia y hemos atendido a diversas personas gracias a ello, tenemos una cartera de clientes tanto en el sector privado, estatal e individual a nivel nacional y extranjero, en general siempre todos nuestros clientes están satisfechos con nuestros productos.
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Categoría de Productos</h6>
              <p>
                <a href='/productos/trofeos-de-vidrio' className='text-reset' style={{ textDecoration: 'none'}}>
                Trofeos de Vidrio
                </a>
              </p>
              <p>
                <a href='/sub-categoria/placas-de-inauguracion' className='text-reset' style={{ textDecoration: 'none'}}>
                Placas de Inauguración
                </a>
              </p>
              <p>
                <a href='/productos/trofeos-variados' className='text-reset' style={{ textDecoration: 'none'}}>
                Trofeos Variados
                </a>
              </p>
            
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Sub Categoría de Productos</h6>
              <p>
                <a href='/productos/bronce-fundido' className='text-reset' style={{ textDecoration: 'none'}}>
                Bronce Fundido
                </a>
              </p>
              <p>
                <a href='/productos/lamina-bronce' className='text-reset' style={{ textDecoration: 'none'}}>
                Lamina Bronce
                </a>
              </p>
              <p>
                <a href='/productos/marmol-granito' className='text-reset' style={{ textDecoration: 'none'}}>
                Marmol Granito
                </a>
              </p>
              <p>
                <a href='/productos/placa-acrilica' className='text-reset' style={{ textDecoration: 'none'}}>
                Placa Acrílica
                </a>
              </p>
              <p>
                <a href='/productos/placa-acrilica-serigrafia' className='text-reset' style={{ textDecoration: 'none'}}>
                Placa Acrílica Serigrafía
                </a>
              </p>
              <p>
                <a href='/productos/placas-de-vidrio' className='text-reset' style={{ textDecoration: 'none'}}>
                Placas De Vidrio
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>CONTACTO</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                Peru, Lima
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                detallesperu@hotmail.com
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> + 51 995 533 887
              </p>
              <h6 className='text-uppercase fw-bold mb-4'>SERVICIO AL CLIENTE</h6>
              <Link rel="canonical" className="navbar-brand fs-4 " to="/reclamaciones"> <img alt="libro de reclamaciones" src="../../assets/libro-reclamaciones.jpeg" width="90px" height="90px" className="d-inline-block align-top" /> </Link>
               
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold nav-link' href='https://detallesperu.com.pe' >
        {' '}https://detallesperu.com.pe
        </a>
      </div>
    </MDBFooter>
    </>
  );
};

export default Footer;

import React from "react";
import { Footer, Navbar } from "../components";
const Reclamaciones = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <h1 className="text-center">Libro de Reclamaciones</h1>
        <hr />
        <h2>Identificación del Consumidor</h2>              
            <form name="contact2" method="POST" netlify action="/" >
            <div class="row">
            <input type="hidden" name="form-name" value="contact2" />
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">              
              <div className="mb-3">              
                <label for="Nombres">Nombre Completo</label>
                <input
                  type="text"
                  class="form-control"
                  id="nombres"
                  name="nombres"
                />
              </div>
            </div>              
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3">  
                <label for="TipoDocumento">Tipo de Documento</label>
                <select className="form-select" id="tipoDocumento" name="tipoDocumento"> 
                  <option value="RUC">RUC</option>
                  <option value="CARNET EXTRANJERIA">Carnet de Extranjería</option>
                  <option value="PASAPORTE">Pasaporte</option>
                  <option value="DNI">DNI</option>                   
                </select>                
                </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="NumeroDocumento">Número de Documento</label>
                <input
                  type="text"
                  class="form-control"
                  id="numeroDocumento"
                  name="numeroDocumento"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Celular">Celular</label>
                <input
                  type="text"
                  class="form-control"
                  id="celular"
                  name="celular"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Email">Correo Electrónico</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Departamento">Departamento</label>
                <input
                  type="text"
                  class="form-control"
                  id="departamento"
                  name="departamento"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Provincia">Provincia</label>
                <input
                  type="text"
                  class="form-control"
                  id="provincia"
                  name="provincia"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Distrito">Distrito</label>
                <input
                  type="text"
                  class="form-control"
                  id="distrito"
                  name="distrito"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Direccion">Dirección</label>
                <input
                  type="text"
                  class="form-control"
                  id="direccion"
                  name="direccion"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
            <div className="mb-3"> 
            </div>
            </div>
            <h2>Identificación del bien contratado</h2>
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="TipoProducto">¿Fue un producto o Servicio?</label>
                <select className="form-select" id="tipoProducto" name="tipoProducto">
                  <option value="PRODUCTO">Producto</option>
                  <option value="SERVICIO">Servicio</option>        
                </select>
              </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Phone">Monto Reclamado</label>
                <input
                  type="text"
                  class="form-control"
                  id="monto"
                  name="monto"
                />
              </div>    
              </div>          
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="DescripcionProducto">Descripción de Producto o Servicio</label>
                <textarea
                  rows={5}
                  class="form-control"               
                  id="descripcionProducto"
                  name="descripcionProducto"
                />
              </div>
              </div>  
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
            <div className="mb-3"> 
            </div>
            </div>
              <h2>Detalle del reclamo del pedido del consumidor</h2>
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Reclamo">¿Es un reclamo o queja?</label>
                <select className="form-select" id="reclamo" name="reclamo">
                  <option value="RECLAMO">Reclamo</option>
                  <option value="QUEJA">Queja</option>        
                </select>
              </div>
              </div>  
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="FechaOcurrencia">Fecha de ocurrencia</label>                
                <input
                  type="date"
                  class="form-control"
                  id="fechaOcurrencia"
                  name="fechaOcurrencia"
                />
              </div>
              </div>  
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="Pedido">Número de Pedido</label>
                <input
                  type="text"
                  class="form-control"
                  id="pedido"
                  name="pedido"
                />
              </div>
              </div>  
              <div className="col-md-6 col-lg-6 col-sm-12 mx-auto">   
              <div className="mb-3"> 
                <label for="DetalleReclamo">Detalle de reclamo o queja</label>
                <textarea
                  rows={5}
                  class="form-control"               
                  id="detalleReclamo"
                  name="detalleReclamo"
                />
              </div>
              </div>  
              <div className="text-center">
                <button
                  class="my-2 px-4 mx-auto btn btn-dark"
                  type="submit"                  
                >
                  Enviar a libro de reclamaciones
                </button>
                <button type="button" className="btn btn-secondary ms-2" onClick={() => window.print()}>Imprimir</button>
              </div>
              </div>
            </form>
            
        </div>        
      
      <Footer />
    </>
  );
};

export default Reclamaciones;
